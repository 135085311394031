// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("GolosUI_Regular.eot", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("GolosUI_Regular.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("GolosUI_Regular.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("GolosUI_Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face{font-family:"Golos UI";src:url(${___CSS_LOADER_URL_REPLACEMENT_0___});src:url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("embedded-opentype"),url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("woff2"),url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("woff"),url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format("truetype");font-weight:normal;font-style:normal}`, "",{"version":3,"sources":["webpack://./assets/fonts/GolosUI/GolosUI_Regular.css"],"names":[],"mappings":"AAIA,WAEE,sBAAA,CACA,2CAAA,CACD,iPACK,CAIL,kBAAA,CACA,iBAAA","sourcesContent":["/* Generated by ParaType (http://www.paratype.com)*/\n/* Font Golos UI: Copyright ? ParaType, 2018. All rights reserved.*/\n\n\n@font-face {\n\t/*font-family: 'GolosUIWebRegular';*/\n  font-family: 'Golos UI';\n  src: url('GolosUI_Regular.eot');\n\tsrc:\n\t     url('GolosUI_Regular.eot?#iefix') format('embedded-opentype'),\n\t     url('GolosUI_Regular.woff2') format('woff2'),\n\t     url('GolosUI_Regular.woff') format('woff'),\n\t     url('GolosUI_Regular.ttf') format('truetype');\n\tfont-weight: normal;\n\tfont-style: normal;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
